import { SRLWrapper } from 'simple-react-lightbox';

// eslint-disable-next-line import/no-anonymous-default-export
export default (props) => {
  const { source, key } = props;
  return (
    <SRLWrapper>
      <a href={source} key={key} data-attribute="SRL">
        <img src={source} key={key} alt="" style={{ maxWidth: '100%' }} />
      </a>
    </SRLWrapper>
  );
};
