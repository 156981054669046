import { NumberInput, SelectInput, useTranslate } from 'react-admin';
import Grid from '@material-ui/core/Grid';
import Row from './../../components/Row';

export const Step4 = () => {
  const translate = useTranslate();

  const durationSlots = [
    {
      id: 30,
      name: translate(
        'resources.experience-drafts.form.step4.duration.values.30'
      ),
    },
    {
      id: 60,
      name: translate(
        'resources.experience-drafts.form.step4.duration.values.60'
      ),
    },
    {
      id: 90,
      name: translate(
        'resources.experience-drafts.form.step4.duration.values.90'
      ),
    },
    {
      id: 120,
      name: translate(
        'resources.experience-drafts.form.step4.duration.values.120'
      ),
    },
    {
      id: 150,
      name: translate(
        'resources.experience-drafts.form.step4.duration.values.150'
      ),
    },
    {
      id: 180,
      name: translate(
        'resources.experience-drafts.form.step4.duration.values.180'
      ),
    },
    {
      id: 240,
      name: translate(
        'resources.experience-drafts.form.step4.duration.values.240'
      ),
    },
    {
      id: 360,
      name: translate(
        'resources.experience-drafts.form.step4.duration.values.360'
      ),
    },
    {
      id: 480,
      name: translate(
        'resources.experience-drafts.form.step4.duration.values.480'
      ),
    },
    {
      id: 720,
      name: translate(
        'resources.experience-drafts.form.step4.duration.values.720'
      ),
    },
    {
      id: 1260,
      name: translate(
        'resources.experience-drafts.form.step4.duration.values.1260'
      ),
    },
    {
      id: 1440,
      name: translate(
        'resources.experience-drafts.form.step4.duration.values.1440'
      ),
    },
  ];

  return (
    <>
      <div style={{ width: '100%' }}>
        <Grid container spacing={3}>
          <Row
            cmp={NumberInput}
            source="price"
            label={translate('resources.experience-drafts.form.step4.price')}
            fullWidth
          />
          <Row
            cmp={SelectInput}
            label={translate(
              'resources.experience-drafts.form.step4.duration.label'
            )}
            source="duration"
            choices={durationSlots}
            fullWidth
          />
        </Grid>
      </div>
    </>
  );
};
