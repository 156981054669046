import { NumberInput, BooleanInput, useTranslate } from 'react-admin';
import Grid from '@material-ui/core/Grid';
import Row from './../../components/Row';
import Chips from '../../components/Chips';
import './step3.scss';

export const Step3 = (props) => {
  const translate = useTranslate();
  return (
    <>
      <div style={{ width: '100%' }}>
        <Grid container spacing={3}>
          <Row
            cmp={Chips}
            source="step3.required_materials"
            label={translate(
              'resources.experience-drafts.form.step3.required_materials'
            )}
            fullWidth
          />
          <Row
            cmp={Chips}
            source="step3.provided_materials"
            label={translate(
              'resources.experience-drafts.form.step3.provided_materials'
            )}
            fullWidth
          />
          <Row
            cmp={NumberInput}
            source="step3.min_participants"
            label={translate(
              'resources.experience-drafts.form.step3.min_participants'
            )}
            fullWidth
          />
          <Row
            cmp={NumberInput}
            source="step3.max_participants"
            label={translate(
              'resources.experience-drafts.form.step3.max_participants'
            )}
            fullWidth
          />
          <Row
            cmp={BooleanInput}
            source="step3.for_children"
            label={translate(
              'resources.experience-drafts.form.step3.for_children'
            )}
            fullWidth
          />
          <Row
            cmp={BooleanInput}
            source="step3.for_young"
            label={translate(
              'resources.experience-drafts.form.step3.for_young'
            )}
            fullWidth
          />
          <Row
            cmp={BooleanInput}
            source="step3.for_elderly"
            label={translate(
              'resources.experience-drafts.form.step3.for_elderly'
            )}
            fullWidth
          />
          <Row
            cmp={BooleanInput}
            source="step3.adapt_for_people_with_disabilities"
            label={translate(
              'resources.experience-drafts.form.step3.adapt_for_people_with_disabilities'
            )}
            fullWidth
          />
        </Grid>
      </div>
    </>
  );
};
