import {
  List,
  Datagrid,
  TextField,
  EditButton,
  DeleteWithConfirmButton,
  useTranslate,
} from 'react-admin';

export const GeoList = (props) => {
  const translate = useTranslate();
  return (
    <List {...props} sort={{ field: 'id', order: 'DESC' }}>
      <Datagrid bulkActionButtons={false}>
        <TextField source="id" />
        <TextField source="title" />
        <TextField source="field" />
        <TextField source="value" />
        <EditButton basePath="/geos" />
        <DeleteWithConfirmButton
          confirmContent={translate('resources.categories.delete_confirm')}
        />
      </Datagrid>
    </List>
  );
};
