import { Edit, Toolbar, SaveButton } from 'react-admin';
import { GeoTitle, Form } from './form';

const EditToolbar = (props) => {
  return (
    <Toolbar {...props}>
      <SaveButton {...props} />
    </Toolbar>
  );
};

export const GeoEdit = (props) => (
  <Edit title={<GeoTitle />} {...props}>
    <Form toolbar={<EditToolbar />} />
  </Edit>
);
