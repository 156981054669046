import Grid from '@material-ui/core/Grid';

// eslint-disable-next-line import/no-anonymous-default-export
export default ({
  cmp: Cmp,
  fullRow = false,
  onClick = () => {},
  ...props
}) => {
  return (
    <Grid item xs={12} lg={fullRow ? 12 : 6}>
      <Cmp {...props} onClick={onClick} />
    </Grid>
  );
};
