import { Fragment } from 'react';
import {
  List,
  Datagrid,
  TextField,
  EditButton,
  DeleteWithConfirmButton,
  useTranslate,
  BulkDeleteWithConfirmButton,
} from 'react-admin';

export const CategoryList = (props) => {
  const translate = useTranslate();
  return (
    <List {...props} sort={{ field: 'id', order: 'DESC' }}>
      <Datagrid bulkActionButtons={false}>
        <TextField source="id" />
        <TextField source="title" />
        <EditButton basePath="/categories" />
        <DeleteWithConfirmButton
          confirmContent={translate('resources.categories.delete_confirm')}
        />
      </Datagrid>
    </List>
  );
};
