import {
  SimpleForm,
  TextInput,
  DateInput,
  SelectInput,
  useTranslate,
  FunctionField,
} from 'react-admin';
import { required, regex, email, choices } from 'react-admin';
import Grid from '@material-ui/core/Grid';
import dayjs from 'dayjs';
import RichTextInput from 'ra-input-rich-text';
import ImageFullScreen from '../components/ImageFullScreen';
import SimpleReactLightbox from 'simple-react-lightbox';
import { Toolbar } from '@material-ui/core';
import ApproveButton from '../components/UserBusinessRequestButtons/ApproveButton';
import ApproveVerifyButton from '../components/UserBusinessRequestButtons/ApproveVerifyButton';
import './form.scss';
import DeleteButton from '../components/UserBusinessRequestButtons/DeleteButton';

export const roleOptions = [
  { id: 'superadmin', name: 'superadmin' },
  { id: 'admin', name: 'admin' },
  { id: 'user', name: 'user' },
];

export const transformer = (data) =>
  Object.assign({}, data, {
    date_of_birth:
      data.date_of_birth && data.date_of_birth.length > 0
        ? data.date_of_birth
        : null,
    delete: data.delete && data.delete.length > 0 ? data.delete : null,
  });

export const UserTitle = ({ record }) => {
  return (
    <span>
      User {record ? `"${record.first_name} ${record.last_name}"` : ''}
    </span>
  );
};

const Row = ({ cmp: Cmp, ...props }) => (
  <Grid item xs={12} lg={6}>
    <Cmp {...props} />
  </Grid>
);

const UserBusinessEditToolbar = (props) => (
  <Toolbar {...props} className="user-business-toolbar">
    <ApproveButton {...props} />
    <ApproveVerifyButton { ...props } />
    <DeleteButton { ...props } />
  </Toolbar>
);

export const Form = (props) => {
  const translate = useTranslate();
  return (
    <SimpleForm {...props} toolbar={<UserBusinessEditToolbar />}>
      <fieldset disabled>
        <div style={{ width: '100%' }}>
          <SimpleReactLightbox>
            <Grid container spacing={3}>
              <Row
                cmp={TextInput}
                label={translate(
                  'resources.user-business-requests.fields.first_name'
                )}
                source="first_name"
                fullWidth
              />
              <Row
                cmp={TextInput}
                label={translate(
                  'resources.user-business-requests.fields.email'
                )}
                source="email"
                fullWidth
              />
              <Row
                cmp={TextInput}
                label={translate(
                  'resources.user-business-requests.fields.last_name'
                )}
                source="last_name"
                fullWidth
              />
              <Row
                cmp={SelectInput}
                label={translate(
                  'resources.user-business-requests.fields.role'
                )}
                source="role"
                choices={roleOptions}
                fullWidth
              />
              <Row
                cmp={TextInput}
                label={translate(
                  'resources.user-business-requests.fields.phone_number'
                )}
                source="phone_number"
                fullWidth
              />
              <Row
                cmp={DateInput}
                label={translate(
                  'resources.user-business-requests.fields.date_of_birth'
                )}
                source="date_of_birth"
                fullWidth
              />
              <Row
                cmp={TextInput}
                label={translate(
                  'resources.user-business-requests.fields.tax_id'
                )}
                source="userBusiness.tax_id"
                fullWidth
              />
              <Row
                cmp={TextInput}
                label={translate(
                  'resources.user-business-requests.fields.iban'
                )}
                source="userBusiness.iban"
                fullWidth
              />
              <Row
                cmp={RichTextInput}
                label={translate(
                  'resources.user-business-requests.fields.biography'
                )}
                source="userBusiness.biography"
                fullWidth
                fullRow
              />
              <Row
                cmp={FunctionField}
                render={(record) => {
                  return (
                    <div style={{ display: 'flex' }}>
                      <ImageFullScreen
                        key={'recordBusinessDocs1'}
                        source={JSON.parse(record.userBusiness.documents)[0]}
                      />
                      <ImageFullScreen
                        key={'recordBusinessDocs2'}
                        source={JSON.parse(record.userBusiness.documents)[1]}
                      />
                    </div>
                  );
                }}
                source="userBusiness.documents"
                label={translate(
                  'resources.user-business-requests.fields.documents'
                )}
                fullWidth
                fullRow
              />
            </Grid>
          </SimpleReactLightbox>
        </div>
      </fieldset>
    </SimpleForm>
  );
};
