import { SimpleForm, useTranslate } from 'react-admin';
import { Step0 } from './steps/Step0';
import { Step1 } from './steps/Step1';
import { Step2 } from './steps/Step2';
import { Step3 } from './steps/Step3';
import { Step4 } from './steps/Step4';
import './form.scss';

export const transformer = (data) =>
  Object.assign({}, data, {
    date_of_birth:
      data.date_of_birth && data.date_of_birth.length > 0
        ? data.date_of_birth
        : null,
    delete: data.delete && data.delete.length > 0 ? data.delete : null,
  });

export const ExperienceTitle = ({ record }) => {
  const translate = useTranslate();
  return (
    <span>
      {translate('resources.experiences.nameSingular')}{' '}
      {record ? `"${record.title}"` : ''}
    </span>
  );
};

export const Form = (props) => {
  const translate = useTranslate();
  return (
    <>
      <SimpleForm {...props}>
        <fieldset disabled>
          <Step0 />
          <div className="divider">
            <hr
              style={{
                width: '100%',
                borderStyle: 'solid',
                marginTop: '20px',
                marginBottom: '20px',
              }}
            />
            <h6>
              {translate('resources.experience-drafts.form.step1.titleSection')}
            </h6>
            <hr
              style={{
                width: '100%',
                borderStyle: 'solid',
                marginTop: '20px',
                marginBottom: '20px',
              }}
            />
          </div>
          <Step1 />
          <div className="divider">
            <hr
              style={{
                width: '100%',
                borderStyle: 'solid',
                marginTop: '20px',
                marginBottom: '20px',
              }}
            />
            <h6>
              {translate('resources.experience-drafts.form.step2.titleSection')}
            </h6>
            <hr
              style={{
                width: '100%',
                borderStyle: 'solid',
                marginTop: '20px',
                marginBottom: '20px',
              }}
            />
          </div>
          <Step2 />
          <div className="divider">
            <hr
              style={{
                width: '100%',
                borderStyle: 'solid',
                marginTop: '20px',
                marginBottom: '20px',
              }}
            />
            <h6>
              {translate('resources.experience-drafts.form.step3.titleSection')}
            </h6>
            <hr
              style={{
                width: '100%',
                borderStyle: 'solid',
                marginTop: '20px',
                marginBottom: '20px',
              }}
            />
          </div>
          <Step3 />
          <div className="divider">
            <hr
              style={{
                width: '100%',
                borderStyle: 'solid',
                marginTop: '20px',
                marginBottom: '20px',
              }}
            />
            <h6>
              {translate('resources.experience-drafts.form.step4.titleSection')}
            </h6>
            <hr
              style={{
                width: '100%',
                borderStyle: 'solid',
                marginTop: '20px',
                marginBottom: '20px',
              }}
            />
          </div>
          <Step4 />
        </fieldset>
      </SimpleForm>
    </>
  );
};
