import {
  SelectInput,
  useTranslate,
  List,
  Datagrid,
  TextField,
  EditButton,
} from 'react-admin';
import * as React from 'react';
import dayjs from 'dayjs';
import ProcessPayment from '../components/UserBusiness/ProcessPayment';

const firstYear = 2021;
const currentYear = dayjs().year();
const years = [{ id: `${firstYear}`, name: `${firstYear}` }];
if (firstYear !== currentYear) {
  const yearDiff = currentYear - firstYear;
  for (let i = 1; i <= yearDiff; i++) {
    years.push({ id: `${firstYear + i}`, name: `${firstYear + i}` });
  }
}

const postFilters = [
  <SelectInput choices={years} alwaysOn source="year" />,
  <SelectInput
    choices={[
      { id: 1, name: 'Gennaio' },
      { id: 2, name: 'Febbraio' },
      { id: 3, name: 'Marzo' },
      { id: 4, name: 'Aprile' },
      { id: 5, name: 'Maggio' },
      { id: 6, name: 'Giugno' },
      { id: 7, name: 'Luglio' },
      { id: 8, name: 'Agosto' },
      { id: 9, name: 'Settembre' },
      { id: 10, name: 'Ottobre' },
      { id: 11, name: 'Novembre' },
      { id: 12, name: 'Dicembre' },
    ]}
    alwaysOn
    source="month"
  />,
];

export const UserBusinessList = (props) => {
  const translate = useTranslate();
  const filterDefaultValues = {
    year: dayjs().year(),
    month: dayjs().month() + 1,
  };
  return (
    <List
      {...props}
      filters={postFilters}
      filterDefaultValues={filterDefaultValues}
      sort={{ field: 'id', order: 'DESC' }}
      bulkActionButtons={false}
    >
      <Datagrid>
        <TextField source="id" />
        <TextField source="full_name" sortable={false} />
        <TextField source="billing_address" sortable={false} />
        <TextField source="tax_id" sortable={false} />
        <TextField source="iban" sortable={false} />
        <TextField source="number_of_experiences" sortable={false} />
        <TextField
          source="totals.total"
          label={translate('resources.user-businesses.fields.total_amount')}
          sortable={false}
        />
        <TextField
          source="totals.totalToPay"
          label={translate('resources.user-businesses.fields.total_toPay')}
          sortable={false}
        />
        <TextField
          source="totals.totalTbp"
          label={translate('resources.user-businesses.fields.total_toTbp')}
          sortable={false}
        />
        <EditButton />
        <ProcessPayment />
      </Datagrid>
    </List>
  );
};
