import * as React from 'react';
import {
  List,
  Datagrid,
  EmailField,
  TextField,
  EditButton,
  DeleteWithConfirmButton,
  FunctionField,
  useTranslate,
} from 'react-admin';

export const UserList = (props) => {
  const translate = useTranslate();

  return (
    <List
      {...props}
      sort={{ field: 'id', order: 'DESC' }}
      bulkActionButtons={false}
    >
      <Datagrid>
        <TextField source="id" />
        <FunctionField
          source="avatar"
          render={(record) => (
            <img
              style={{ width: '50px', height: '50px', borderRadius: '50%' }}
              src={
                record.avatar ??
                'https://res.cloudinary.com/to-be-polo/image/upload/v1601827459/avatars/tpb_avatar_placeholder_qbhbqf.jpg'
              }
            />
          )}
        />
        <EmailField source="email" />
        <TextField source="first_name" />
        <TextField source="last_name" />
        <TextField source="role" />
        <EditButton />
        <DeleteWithConfirmButton
          confirmContent={translate('resources.users.delete_confirm')}
        />
      </Datagrid>
    </List>
  );
};
