import { Button } from '@material-ui/core';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import { useTranslate, useNotify, useRefresh, useGetOne } from 'react-admin';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';
import axios from 'axios';

export default (props) => {
  const translate = useTranslate();
  const notify = useNotify();
  const refresh = useRefresh();
  const { year = dayjs().year(), month = dayjs().month() + 1 } = useSelector(
    (state) => state.admin.resources['user-businesses'].list.params.filter
  );
  const processPayments = () =>
    axios
      .patch(
        `${process.env.REACT_APP_BACKEND_ENDPOINT}/user-businesses/${props.record.id}/process-transactions`,
        { year, month },
        {
          headers: {
            Authorization: `Bearer ${
              JSON.parse(localStorage.getItem('auth')).token
            }`,
          },
        }
      )
      .then(() => {
        refresh();
        notify(
          translate(
            'resources.user-businesses.actions.process_payments-success'
          ),
          { type: 'success' }
        );
      })
      .catch((e) => {
        notify(e.response.data.error.message, { type: 'warning' });
      });
  return (
    <Button
      color="primary"
      disabled={props.record?.totals?.total === 0}
      onClick={processPayments}
    >
      <MonetizationOnIcon />
      {translate('resources.user-businesses.actions.process_payments')}
    </Button>
  );
};
