import {
  TextInput,
  ReferenceInput,
  SelectInput,
  useTranslate,
} from 'react-admin';
import MapBox from './../../components/MapBox';
import Grid from '@material-ui/core/Grid';
import Row from './../../components/Row';

const ReferenceInputCategory = () => {
  const translate = useTranslate();
  return (
    <ReferenceInput
      label={translate('resources.experience-drafts.form.step1.categories')}
      reference="categories"
      source="categoryId"
      target="categoryId"
    >
      <SelectInput optionText="title" fullWidth />
    </ReferenceInput>
  );
};
export const Step1 = () => {
  const translate = useTranslate();
  return (
    <>
      <div style={{ width: '100%' }}>
        <Grid container spacing={3}>
          <Row
            cmp={TextInput}
            source="title"
            label={translate('resources.experience-drafts.form.step1.title')}
            fullWidth
          />
          <Row cmp={ReferenceInputCategory} fullWidth />

          <Row
            cmp={TextInput}
            source="lat"
            label={translate('resources.experience-drafts.form.step1.lat')}
            fullWidth
          />
          <Row
            cmp={TextInput}
            source="lng"
            label={translate('resources.experience-drafts.form.step1.lng')}
            fullWidth
          />
          <Row
            cmp={MapBox}
            source=""
            label={translate('resources.experience-drafts.form.step1.map')}
            fullWidth
            fullRow
          />
        </Grid>
      </div>
    </>
  );
};
