import {
  List,
  Datagrid,
  TextField,
  DateField,
  useTranslate,
  NullableBooleanInput,
  SelectInput,
} from 'react-admin';
import Clear from '@material-ui/icons/Clear';
import dayjs from 'dayjs';

const firstYear = 2021;
const currentYear = dayjs().year();
const years = [{ id: `${firstYear}`, name: `${firstYear}` }];
if (firstYear !== currentYear) {
  const yearDiff = currentYear - firstYear;
  for (let i = 1; i <= yearDiff; i++) {
    years.push({ id: `${firstYear + i}`, name: `${firstYear + i}` });
  }
}

const postFilters = [
  <SelectInput choices={years} alwaysOn source="year" />,
  <SelectInput
    choices={[
      { id: 1, name: 'Gennaio' },
      { id: 2, name: 'Febbraio' },
      { id: 3, name: 'Marzo' },
      { id: 4, name: 'Aprile' },
      { id: 5, name: 'Maggio' },
      { id: 6, name: 'Giugno' },
      { id: 7, name: 'Luglio' },
      { id: 8, name: 'Agosto' },
      { id: 9, name: 'Settembre' },
      { id: 10, name: 'Ottobre' },
      { id: 11, name: 'Novembre' },
      { id: 12, name: 'Dicembre' },
    ]}
    alwaysOn
    source="month"
  />,
  <NullableBooleanInput source="processed" alwaysOn />,
];

export const TransactionList = (props) => {
  const translate = useTranslate();
  const filterDefaultValues = {
    year: dayjs().year(),
    month: dayjs().month() + 1,
  };
  return (
    <List
      {...props}
      filters={postFilters}
      filterDefaultValues={filterDefaultValues}
      sort={{ field: 'id', order: 'DESC' }}
      bulkActionButtons={false}
    >
      <Datagrid>
        <TextField source="id" />
        <TextField source="experience" />
        <TextField source="buyer" />
        <TextField
          source="transaction.amount"
          label={translate('resources.transactions.fields.amount')}
        />
        <TextField source="merchant" />
        <DateField source="processed" emptyText={<Clear />} />
        <DateField
          source="transaction.created_at"
          label={translate('resources.transactions.fields.created_at')}
        />
      </Datagrid>
    </List>
  );
};
