import * as React from 'react';
import { Edit, SaveButton, Toolbar } from 'react-admin';
import { UserTitle, Form, transformer } from './form';

const EditToolbar = (props) => {
  return (
    <Toolbar {...props}>
      <SaveButton {...props} />
    </Toolbar>
  );
};

export const UserEdit = (props) => (
  <Edit title={<UserTitle />} {...props} transform={transformer}>
    <Form disableemail toolbar={<EditToolbar />} />
  </Edit>
);
