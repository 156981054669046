import { useRecordContext } from 'react-admin';
import * as _ from 'lodash';

// eslint-disable-next-line import/no-anonymous-default-export
export default (props) => {
  const { source } = props;
  const record = useRecordContext(props);
  const f = _.get(record, source);
  const field = !Array.isArray(f) ? [f] : f;

  return (
    <video class="video-wrapper" controls>
      <source src={field} type="video/mp4" />
    </video>
  );
};
