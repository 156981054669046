import { AppBar, UserMenu } from 'react-admin';
import UserAvatarPlaceholder from './assets/img/user_avatar_placeholder.jpg';

const MyUserMenu = (props) => {
  return (
    <UserMenu
      {...props}
      icon={
        <img
          src={UserAvatarPlaceholder}
          alt="logo"
          style={{ height: '27px', borderRadius: '50%' }}
        />
      }
    />
  );
};
// eslint-disable-next-line import/no-anonymous-default-export
export default (props) => <AppBar {...props} userMenu={<MyUserMenu />} />;
