import { Button } from '@material-ui/core';
import IconDeleteForever from '@material-ui/icons/DeleteForever';
import { useRefresh, useTranslate, useNotify, useRedirect } from 'react-admin';

import axios from 'axios';

export default (props) => {
  const translate = useTranslate();
  const refresh = useRefresh();
  const notify = useNotify();
  const redirect = useRedirect();
  const deleteUser = () =>
    axios
      .delete(
        `${process.env.REACT_APP_BACKEND_ENDPOINT}/user-business-requests/${props.record.userBusiness.id}`,
        {
          headers: {
            Authorization: `Bearer ${
              JSON.parse(localStorage.getItem('auth')).token
            }`,
          },
        }
      )
      .then(() => {
        notify(
          translate(
            'resources.user-business-requests.response.delete-success'
          ),
          { type: 'success' }
        );
        setTimeout(() => {
          redirect('/user-business-requests');
        }, 1000);
      })
      .catch((e) => {
        notify(e.response.data.error.message, { type: 'warning' });
      });
  return (
    <Button color="primary" onClick={ deleteUser }>
      <IconDeleteForever />
      {translate('resources.user-business-requests.actions.delete')}
    </Button>
  );
};
