import * as React from 'react';
import {
  List,
  Datagrid,
  TextField,
  EditButton,
  ReferenceField,
  DeleteWithConfirmButton,
  TextInput,
  useTranslate,
} from 'react-admin';
import { CategorySelect } from './form';

const tagsFilter = (translate) => [
  <TextInput
    label={translate('resources.tags.filters.search')}
    source="q"
    alwaysOn
  />,
  <CategorySelect
    label={translate('resources.tags.filters.category')}
    source="categoryId"
    reference="categories"
    alwaysOn
  />,
];
export const TagsList = (props) => {
  const translate = useTranslate();
  return (
    <List
      {...props}
      filters={tagsFilter(translate)}
      sort={{ field: 'id', order: 'DESC' }}
      bulkActionButtons={false}
    >
      <Datagrid>
        <TextField source="id" />
        <TextField source="title" />
        <ReferenceField
          label={translate('resources.tags.fields.category')}
          source="categoryId"
          reference="categories"
          sortBy="categoryId"
          link=""
        >
          <TextField source="title" />
        </ReferenceField>
        <EditButton basePath="/tags" />
        <DeleteWithConfirmButton
          confirmContent={translate('resources.tags.delete_confirm')}
        />
      </Datagrid>
    </List>
  );
};
