import IconCancel from '@material-ui/icons/Cancel';
import Modal from '@material-ui/core/Modal';
import React from 'react';
import axios from 'axios';
import { useRefresh, useTranslate, useNotify } from 'react-admin';
import { Button } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';

export default (props) => {
  const notify = useNotify();
  const [modal, openModal] = React.useState(false);
  const [reason, setReason] = React.useState('');
  const cancelBookingByUser = () =>
    axios
      .patch(
        `${process.env.REACT_APP_BACKEND_ENDPOINT}/bookings/${props.record.id}/cancel-user`,
        { reason },
        {
          headers: {
            Authorization: `Bearer ${
              JSON.parse(localStorage.getItem('auth')).token
            }`,
          },
        }
      )
      .then(() => {
        notify(translate('resources.action.success.generic'), {
          type: 'success',
        });
        refresh();
      })
      .catch((e) => {
        notify(e.response.data.error.message, { type: 'warning' });
      });
  const refresh = useRefresh();
  const translate = useTranslate();
  return (
    <>
      <Modal
        open={modal}
        onClose={() => openModal(false)}
        className="rejectModal"
      >
        <div style={{}} className="content">
          <h4>{translate('resources.bookings.action.reasonMessage')}</h4>
          <Grid container>
            <Grid item xs={12}>
              <TextField
                label={translate('resources.bookings.action.reason')}
                multiline
                onChange={(e) => setReason(e.target.value)}
                fullWidth
                rows={4}
                variant="outlined"
              />
            </Grid>
          </Grid>
          <Grid container justifyContent="flex-end">
            <Grid
              item
              xs={4}
              style={{ display: 'flex', justifyContent: 'end' }}
            >
              <Button
                color="primary"
                disabled={props.record.status !== 'pending'}
                onClick={cancelBookingByUser}
              >
                <IconCancel />
                {translate('resources.bookings.action.cancel_user')}
              </Button>
            </Grid>
          </Grid>
        </div>
      </Modal>
      <Button
        color="primary"
        disabled={props.record.status !== 'pending'}
        onClick={() => openModal(true)}
      >
        <IconCancel />
        {translate('resources.bookings.action.cancel_user')}
      </Button>
    </>
  );
};
