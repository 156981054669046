import { useState } from 'react';
import { Button } from '@material-ui/core';
import IconCheckCircle from '@material-ui/icons/CheckCircle';
import { useRefresh, useTranslate, useNotify, Confirm } from 'react-admin';
import dayjs from 'dayjs';

import axios from 'axios';

export default (props) => {
  const translate = useTranslate();
  const notify = useNotify();
  const [open, setOpen] = useState(false);
  const handleClick = () => setOpen(true);
  const handleDialogClose = () => setOpen(false);
  const deleteReview = () =>
    axios
      .delete(
        `${process.env.REACT_APP_BACKEND_ENDPOINT}/reviews/${props.record.id}`,
        {
          headers: {
            Authorization: `Bearer ${
              JSON.parse(localStorage.getItem('auth')).token
            }`,
          },
        }
      )
      .then(() => {
        setOpen(false);
        notify(translate('resources.reviews.response.delete_success'), {
          type: 'success',
        });
      })
      .catch((e) => {
        setOpen(false);
        notify(e.response.data.error.message, { type: 'warning' });
      });

  return (
    <>
      <Button color="primary" onClick={handleClick}>
        {translate('resources.reviews.action.delete.button')}
      </Button>
      <Confirm
        isOpen={open}
        title={translate('resources.reviews.action.delete.title')}
        content={translate('resources.reviews.action.delete.content')}
        onConfirm={deleteReview}
        onClose={handleDialogClose}
      />
    </>
  );
};
