import * as React from 'react';
import {
  List,
  Datagrid,
  TextField,
  EditButton,
  FunctionField,
  SelectInput,
  useTranslate,
} from 'react-admin';
import ApproveButton from '../components/ExperienceDraftButtons/ApproveButton';
import RejectButton from '../components/ExperienceDraftButtons/RejectButton';
import SearchIcon from '@material-ui/icons/Search';

const postFilters = (translate) => [
  <SelectInput
    choices={[
      {
        id: 'draft',
        name: translate('resources.experience-drafts.filters.draft'),
      },
      {
        id: 'submitted',
        name: translate('resources.experience-drafts.filters.submitted'),
      },
      {
        id: 'rejected',
        name: translate('resources.experience-drafts.filters.rejected'),
      },
    ]}
    initialValue="submitted"
    alwaysOn
    source="status"
  />,
];

const getUser = ({ first_name = '', last_name = '' } = {}) => {
  return `${first_name} ${last_name}`;
};
const CustomDataGrid = ({ translate, ...props }) => {
  return (
    <Datagrid {...props}>
      <TextField source="id" />
      <FunctionField
        label={translate('resources.experience-drafts.fields.title')}
        render={(exp_draft) => exp_draft.step1.title}
      />
      <TextField source="status" />
      <FunctionField
        label={translate('resources.experience-drafts.fields.user')}
        render={(exp) => getUser(exp.userBusiness && exp.userBusiness.user)}
      />
      {props.filterValues.status === 'rejected' && (
        <TextField source="reason" />
      )}
      {props.filterValues.status === 'rejected' && (
        <TextField source="reviewCount" />
      )}
      <EditButton
        basePath="/experience-drafts"
        label={translate('resources.experience-drafts.actions.edit')}
        icon={<SearchIcon />}
      />
      {props.filterValues.status === 'submitted' && <ApproveButton />}
      {props.filterValues.status === 'submitted' && <RejectButton />}
    </Datagrid>
  );
};
export const ExperienceDraftsList = (props) => {
  const translate = useTranslate();
  return (
    <List
      {...props}
      filters={postFilters(translate)}
      filterDefaultValues={{ status: 'submitted' }}
      sort={{ field: 'id', order: 'DESC' }}
      bulkActionButtons={false}
    >
      <CustomDataGrid translate={translate} />
    </List>
  );
};
