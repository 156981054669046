import * as React from 'react';
import { Edit, Toolbar, SaveButton } from 'react-admin';
import { TagTitle, Form } from './form';

const EditToolbar = (props) => {
  return (
    <Toolbar {...props}>
      <SaveButton {...props} />
    </Toolbar>
  );
};

export const TagsEdit = (props) => {
  return (
    <Edit title={<TagTitle />} {...props}>
      <Form toolbar={<EditToolbar />} />
    </Edit>
  );
};
