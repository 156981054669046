import * as React from 'react';
import {
  List,
  Datagrid,
  TextField,
  FunctionField,
  useTranslate,
  ShowButton,
} from 'react-admin';
import ApproveButton from '../components/UserBusinessRequestButtons/ApproveButton';
import ApproveVerifyButton from '../components/UserBusinessRequestButtons/ApproveVerifyButton';
import DeleteButton from '../components/UserBusinessRequestButtons/DeleteButton';

export const UserBusinessRequestList = (props) => {
  const translate = useTranslate();
  return (
    <List
      {...props}
      sort={{ field: 'id', order: 'DESC' }}
      bulkActionButtons={false}
    >
      <Datagrid>
        <TextField source="id" />
        <FunctionField
          label={translate('resources.user-business-requests.fields.full_name')}
          render={(record) => `${record.first_name} ${record.last_name}`}
        />
        <TextField
          label={translate(
            'resources.user-business-requests.fields.billing_address'
          )}
          source="billing_address"
        />
        <TextField
          label={translate('resources.user-business-requests.fields.tax_id')}
          source="userBusiness.tax_id"
        />
        <TextField
          label={translate('resources.user-business-requests.fields.iban')}
          source="userBusiness.iban"
        />
        <ShowButton basePath="/user-business-requests" />
        <ApproveButton />
        <ApproveVerifyButton />
        <DeleteButton />
      </Datagrid>
    </List>
  );
};
