import ChipInput from 'material-ui-chip-input';
import Chip from '@material-ui/core/Chip';
import Box from '@material-ui/core/Box';
import { useInput } from 'react-admin';
import './style.scss';

// eslint-disable-next-line import/no-anonymous-default-export
export default ({ label, ...props }) => {
  const {
    input: { onChange, value: values },
  } = useInput(props);

  return (
    <ChipInput
      value={values}
      chipRenderer={({ text }, key) => (
        <Box key={key} ml={0.5} mb={0.5}>
          <Chip label={text} />
        </Box>
      )}
      onAdd={(v) => onChange([...values, v])}
      onDelete={(_value, index) => {
        values.splice(index, 1);
      }}
    />
  );
};
