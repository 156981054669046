import * as React from 'react';
import { Edit, Toolbar } from 'react-admin';
import DeleteBusinessResponse from '../components/Reviews/DeleteBusinessResponse';
import DeleteReview from '../components/Reviews/DeleteReview';
import { Form } from './form';

const EditToolbar = (props) => {
  return (
    <Toolbar {...props}>
      <DeleteBusinessResponse {...props} />
      <DeleteReview {...props} />
    </Toolbar>
  );
};

export const ReviewEdit = (props) => (
  <Edit {...props}>
    <Form toolbar={<EditToolbar />} />
  </Edit>
);
