import * as React from 'react';
import {
  List,
  Datagrid,
  TextField,
  useTranslate,
  ReferenceInput,
  SelectInput,
  ShowButton,
  EditButton,
} from 'react-admin';

const postFilters = () => [
  <ReferenceInput
    label="Esperienza"
    source="id"
    reference="experiences"
    alwaysOn
  >
    <SelectInput optionText="title" />
  </ReferenceInput>,
];

export const ReviewList = (props) => {
  const translate = useTranslate();

  return (
    <List
      {...props}
      sort={{ field: 'id', order: 'DESC' }}
      bulkActionButtons={false}
      filters={postFilters()}
    >
      <Datagrid>
        <TextField source="id" />
        <TextField source="title" />
        <TextField source="experience" />
        <TextField source="buyer" />
        <TextField source="seller" />
        <TextField source="score" />
        <TextField source="date" />
        <EditButton />
      </Datagrid>
    </List>
  );
};
