import React, { useEffect, useState } from 'react';
import Uppy from '@uppy/core';
import { DashboardModal } from '@uppy/react';
import XHRUpload from '@uppy/xhr-upload';
import Compressor from '@uppy/compressor';
import IT from '@uppy/locales/lib/it_IT';
import { Button } from '@material-ui/core';
import { useInput, useTranslate } from 'react-admin';

const FileUploader = ({
  allowedFileTypes,
  extraParameters,
  label,
  triggerText,
  maxFileSize,
  minNumberOfFiles,
  maxNumberOfFiles,
  ...props
}) => {
  const {
    input: { onChange, ...rest },
  } = useInput(props);

  const translate = useTranslate();

  const [file, setFile] = useState(rest.value ?? null);
  const [isOpen, setIsOpen] = useState(false);

  const [uppy] = useState(() =>
    new Uppy({
      locale: IT,
      autoProceed: false,
      restrictions: {
        maxFileSize,
        minNumberOfFiles,
        maxNumberOfFiles,
        allowedFileTypes,
      },
    })
      .use(Compressor, {
        quality: 0.6,
        limit: 10,
      })
      .use(XHRUpload, {
        endpoint: 'https://api.cloudinary.com/v1_1/to-be-polo/upload',
        formData: true,
        metaFields: ['file', 'name', 'upload_preset'],
      })
  );

  uppy.on('complete', (result) => {
    setFile(result.successful[0].uploadURL);
    onChange(result.successful[0].uploadURL);
    setIsOpen(false);
    uppy.reset();
  });

  uppy.on('file-added', (file) => {
    uppy.setFileMeta(file.id, {
      file: file.data,
      upload_preset: process.env.REACT_APP_CLOUDINARY_UPLOAD_PRESET,
      ...extraParameters,
    });
  });

  return (
    <div>
      {label && <label>{label}</label>}
      <div>
        <img src={file} style={{ width: 250 }} />
      </div>
      <Button color="primary" type="button" onClick={() => setIsOpen(true)}>
        {triggerText ? triggerText : translate('resources.buttons.upload')}
      </Button>
      <DashboardModal
        uppy={uppy}
        open={isOpen}
        showLinkToFileUploadResult={false}
        hideProgressAfterFinish={true}
        proudlyDisplayPoweredByUppy={false}
        showProgressDetails={true}
        closeModalOnClickOutside
        onRequestClose={() => {
          setIsOpen(false);
        }}
      />
    </div>
  );
};

export default FileUploader;
