import {
  ReferenceArrayInput,
  AutocompleteArrayInput,
  SelectInput,
  SimpleForm,
  TextInput,
  useTranslate,
} from 'react-admin';
import Row from '../components/Row';
import FileUploader from '../components/FileUploader';
import Grid from '@material-ui/core/Grid';
import { BooleanInput } from 'react-admin';

export const fieldOptions = [
  { id: 'city', name: 'Città' },
  { id: 'province', name: 'Provincia' },
];

export const GeoTitle = ({ record }) => {
  return <span>Category {record ? `"${record.title}"` : ''}</span>;
};

const Cities = () => {
  return (
    <ReferenceArrayInput
      source="value"
      reference="cities"
      perPage={8000}
      sort={{ field: 'city', order: 'ASC' }}
    >
      <AutocompleteArrayInput
        label="Città"
        source="city"
        optionText="city"
        optionValue="city"
suggestionLimit={100}
      />
    </ReferenceArrayInput>
  );
};

export const Form = (props) => {
  const translate = useTranslate();

  return (
    <SimpleForm {...props}>
      <Grid container spacing={3}>
        <Row cmp={ TextInput } source="title" label={ translate('resources.geos.fields.title') } />
        <Row cmp={ SelectInput } source="field" label={ translate('resources.geos.fields.field') } choices={fieldOptions} />
      </Grid>
      <Grid container spacing={3}>
        <Row cmp={ Cities } />
        <Row cmp={ BooleanInput } source="isActive" label={ translate('resources.geos.fields.isActive') } />
      </Grid>

      <Grid container spacing={3}>
        <Row
          cmp={FileUploader}
          maxNumberOfFiles={1}
          triggerText={ translate('resources.geos.fields.trigger.featured_image')}
          source="featured_image"
        />
        <Row
          cmp={FileUploader}
          maxNumberOfFiles={1}
          triggerText={translate('resources.geos.fields.trigger.card_image')}
          source="card_image"
        />
      </Grid>
    </SimpleForm>
  );
};
