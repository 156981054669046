import { Button } from '@material-ui/core';
import IconCheckCircle from '@material-ui/icons/CheckCircle';
import { useRefresh, useTranslate, useNotify } from 'react-admin';

import axios from 'axios';

export default (props) => {
  const translate = useTranslate();
  const refresh = useRefresh();
  const notify = useNotify();
  const approveUser = () =>
    axios
      .patch(
        `${process.env.REACT_APP_BACKEND_ENDPOINT}/user-business-requests/${props.record.userBusiness.id}/approve`,
        {},
        {
          headers: {
            Authorization: `Bearer ${
              JSON.parse(localStorage.getItem('auth')).token
            }`,
          },
        }
      )
      .then(() => {
        notify(
          translate(
            'resources.user-business-requests.response.approve-success'
          ),
          { type: 'success' }
        );
      })
      .catch((e) => {
        notify(e.response.data.error.message, { type: 'warning' });
      });
  return (
    <Button color="primary" onClick={approveUser}>
      <IconCheckCircle />
      {translate('resources.user-business-requests.actions.approve')}
    </Button>
  );
};
