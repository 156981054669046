import { SimpleForm, TextInput, useTranslate } from 'react-admin';
import RichTextInput from 'ra-input-rich-text';
import Grid from '@material-ui/core/Grid';

const Row = ({ cmp: Cmp, ...props }) => (
  <Grid item xs={12} lg={6}>
    <Cmp {...props} />
  </Grid>
);

export const Form = (props) => {
  const translate = useTranslate();

  return (
    <SimpleForm {...props}>
      <fieldset disabled>
        <div style={{ width: '100%' }}>
          <Grid container spacing={3}>
            <Row
              cmp={TextInput}
              label={translate('resources.reviews.fields.title')}
              source="title"
              fullWidth
            />
            <Row
              cmp={TextInput}
              label={translate('resources.reviews.fields.score')}
              source="score"
              fullWidth
            />
            <Row
              cmp={RichTextInput}
              label={translate('resources.reviews.fields.description')}
              source="description"
              fullWidth
            />
            <Row
              cmp={RichTextInput}
              label={translate('resources.reviews.fields.businessResponse')}
              source="businessResponse"
              fullWidth
            />
          </Grid>
        </div>
      </fieldset>
    </SimpleForm>
  );
};
