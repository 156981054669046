import jwt_decode from 'jwt-decode';

export const authProvider = {
  login: ({ username, password }) => {
    const request = new Request(
      `${process.env.REACT_APP_BACKEND_ENDPOINT}/auth/login`,
      {
        method: 'PUT',
        body: JSON.stringify({ email: username, password }),
        headers: new Headers({ 'Content-Type': 'application/json' }),
      }
    );
    return fetch(request)
      .then((response) => {
        if (response.status < 200 || response.status >= 300) {
          throw new Error(response.statusText);
        }
        return response.json();
      })
      .then((auth) => {
        localStorage.setItem('auth', JSON.stringify(auth));
      })
      .catch(() => {
        throw new Error('Network error');
      });
  },
  checkError: (error) => {
    const status = error.status;
    if (
      status === 401 ||
      status === 403 ||
      error.message.includes('jwt expired')
    ) {
      return Promise.reject({ redirectTo: '/auth/login', logoutUser: true });
    }
    // other error code (404, 500, etc): no need to log out
    return Promise.resolve();
  },
  checkAuth: () =>
    localStorage.getItem('auth') ? Promise.resolve() : Promise.reject(),
  logout: () => {
    localStorage.removeItem('auth');
    return Promise.resolve();
  },
  getIdentity: () => {
    try {
      const { id, first_name, last_name, avatar } = jwt_decode(
        JSON.parse(localStorage.getItem('auth')).token
      );
      const fullName = first_name + ' ' + last_name;
      return Promise.resolve({ id, fullName, avatar });
    } catch (error) {
      return Promise.reject(error);
    }
  },
  // authorization
  getPermissions: (params) => Promise.resolve(),
};

export const getHeaders = async () => {
  const { token } = JSON.parse(localStorage.getItem('auth'));
  return {
    Authorization: `Bearer ${token}`,
  };
};
