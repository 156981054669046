// in src/App.js
import * as React from 'react';
import { Admin, Resource, Layout, Sidebar as RASidebar } from 'react-admin';
import lb4Provider from 'react-admin-lb4';
import { UserList, UserEdit, UserCreate, UserIcon } from './users';
import { authProvider, getHeaders } from './authProvider';
import {
  CategoryList,
  CategoryEdit,
  CategoryCreate,
  CategoryIcon,
} from './categories';
import { GeoList, GeoEdit, GeoCreate, GeoIcon } from './geos';
import { TagsList, TagsCreate, TagsEdit, TagsIcon } from './tags';
import theme from './theme';
import Login from './Login';
import AppBar from './AppBar';
import {
  ExperiencesList,
  ExperiencesEdit,
  ExperiencesIcon,
} from './experiences';
import { TransactionList, TransactionIcon } from './transactions';
import { BookingList, BookingIcon } from './bookings';
import {
  ExperienceDraftsList,
  ExperienceDraftsEdit,
  ExperienceDraftsIcon,
} from './experience-drafts';
import {
  UserBusinessList,
  UserBusinessCreate,
  UserBusinessEdit,
  UserBusinessIcon,
} from './users-business';
import {
  UserBusinessRequestList,
  UserBusinessRequestEdit,
  UserBusinessRequestIcon,
} from './users-business-requests';

import '@uppy/core/dist/style.css';
import '@uppy/dashboard/dist/style.css';
import '@uppy/drag-drop/dist/style.css';
import '@uppy/progress-bar/dist/style.css';
import polyglotI18nProvider from 'ra-i18n-polyglot';
import it from 'ra-language-italian';
import labels from './locales/common.json';
import './App.scss';
import { ReviewList, ReviewEdit, ReviewIcon } from './reviews';

export const i18nProvider = polyglotI18nProvider(
  () => Object.assign({}, it, labels),
  'it' // Default locale
);

const dataProvider = lb4Provider(
  process.env.REACT_APP_BACKEND_ENDPOINT,
  getHeaders
);

const Sidebar = (props) => <RASidebar {...props} className="tbp-sidebar" />;

const TBPLayout = (props) => (
  <Layout {...props} sidebar={Sidebar} appBar={AppBar} />
);

const App = () => (
  <Admin
    loginPage={Login}
    layout={TBPLayout}
    theme={theme}
    dataProvider={dataProvider}
    authProvider={authProvider}
    i18nProvider={i18nProvider}
  >
    <Resource
      name="experience-drafts"
      list={ExperienceDraftsList}
      edit={ExperienceDraftsEdit}
      icon={ExperienceDraftsIcon}
    />
    <Resource
      name="user-business-requests"
      list={UserBusinessRequestList}
      edit={UserBusinessRequestEdit}
      icon={UserBusinessRequestIcon}
    />
    <Resource
      name="experiences"
      list={ExperiencesList}
      edit={ExperiencesEdit}
      icon={ExperiencesIcon}
    />
    <Resource
      name="reviews"
      list={ReviewList}
      edit={ReviewEdit}
      icon={ReviewIcon}
    />
    <Resource
      name="users"
      list={UserList}
      edit={UserEdit}
      create={UserCreate}
      icon={UserIcon}
    />
    <Resource
      name="user-businesses"
      list={UserBusinessList}
      create={UserBusinessCreate}
      edit={UserBusinessEdit}
      icon={UserBusinessIcon}
    />
    <Resource
      name="transactions"
      list={TransactionList}
      icon={TransactionIcon}
    />
    <Resource name="bookings" list={BookingList} icon={BookingIcon} />

    <Resource
      name="categories"
      list={CategoryList}
      edit={CategoryEdit}
      create={CategoryCreate}
      icon={CategoryIcon}
    />
    <Resource
      name="geos"
      list={GeoList}
      edit={GeoEdit}
      create={GeoCreate}
      icon={GeoIcon}
    />
    <Resource
      name="tags"
      list={TagsList}
      edit={TagsEdit}
      create={TagsCreate}
      icon={TagsIcon}
    />
    <Resource name="cities" />
  </Admin>
);

export default App;
