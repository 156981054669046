import React, { useState, useRef, useEffect } from 'react';
import mapboxgl from 'mapbox-gl';
import { useInput } from 'react-admin';
import VisibilitySensor from 'react-visibility-sensor';
import { initializeGeocoder, parseGeo } from './util';
import './style.scss';

const MapAutoComplete = ({
  mapBoxConfig: {
    scrollZoom,
    boxZoom,
    doubleClickZoom,
    dragPan,
    flySpeed,
    flyZoom,
    zoom,
  },
  visible,
  label,
  ...props
}) => {
  const {
    input: { onChange, ...rest },
  } = useInput(props);

  const {
    value: { address, lat, lng },
  } = rest;

  const [locationData, setLocationData] = useState(null);
  const [map, setMap] = useState(null);
  const [geocoder, setGeocoder] = useState(null);
  const [marker, setMarker] = useState(null);
  const mapContainer = useRef(null);
  const geocoderContainer = useRef(null);

  // update location data
  useEffect(() => {
    setLocationData({
      ...locationData,
      address,
      lat,
      lng,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [address, lat, lng]);

  useEffect(() => {
    mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;

    const map = new mapboxgl.Map({
      container: mapContainer.current,
      style: 'mapbox://styles/mapbox/streets-v11',
      center: [lng, lat],
      zoom,
      scrollZoom,
      boxZoom,
      doubleClickZoom,
      dragPan,
    });
    map.addControl(new mapboxgl.NavigationControl());
    setMap(map);

    if (!geocoder) {
      setGeocoder(
        initializeGeocoder({
          geocoderContainer,
          address,
          onResult: (e) => {
            if (e.result) {
              const location = parseGeo(e.result);
              setLocationData(location);
              rest.value.lng = location.lng;
              rest.value.lat = location.lat;
              rest.value.address = location.complete;
              rest.onBlur();
            }
          },
        })
      );
    }
  }, []);

  useEffect(() => {
    if (geocoder) {
      geocoder.setPlaceholder(address);
    }
  }, [geocoder, address]);

  useEffect(() => {
    map && map.resize();
  }, [map, visible]);

  // update map marker position
  useEffect(() => {
    if (!locationData || (!locationData.lng && !locationData.lat)) {
      return;
    }
    setMarker(
      (!marker ? new mapboxgl.Marker() : marker)
        .setLngLat([locationData.lng, locationData.lat])
        .remove()
        .addTo(map)
    );
    map.flyTo({
      center: [locationData.lng, locationData.lat],
      zoom: flyZoom,
      speed: flySpeed,
    });
  }, [flySpeed, flyZoom, locationData, map, marker]);

  return (
    <div className="geocoder-map MapAutoCompleteWrapper">
      <div
        className="geocoder GeocoderContainer"
        ref={(el) => (geocoderContainer.current = el)}
      ></div>
      <div
        className="map MapContainer"
        ref={(el) => (mapContainer.current = el)}
      />
    </div>
  );
};

MapAutoComplete.defaultProps = {
  label: '',
  mapBoxConfig: {
    scrollZoom: false,
    boxZoom: false,
    doubleClickZoom: false,
    dragPan: true,
    flySpeed: 1.25,
    flyZoom: 14,
    zoom: 14,
  },
  visible: false,
  source: '',
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (props) => {
  const [visible, setVisible] = React.useState(false);
  return (
    <VisibilitySensor onChange={setVisible}>
      <MapAutoComplete {...props} visible={visible} />
    </VisibilitySensor>
  );
};
