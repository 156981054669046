import {
  SimpleForm,
  TextInput,
  ReferenceInput,
  SelectInput,
  required,
  useNotify,
  useTranslate,
} from 'react-admin';
import Row from './../components/Row';
import Grid from '@material-ui/core/Grid';

export const TagTitle = ({ record }) => {
  const translate = useTranslate();
  return (
    <span>
      {translate('resources.tags.name')} {record ? `"${record.title}"` : ''}
    </span>
  );
};

export const CategorySelect = (props) => (
  <ReferenceInput {...props}>
    <SelectInput optionText="title" />
  </ReferenceInput>
);

export const Form = (props) => {
  const notify = useNotify();
  const translate = useTranslate();
  const onFailure = (error) => {
    console.log(error);
    notify(`Could not edit: ${error.message}`);
  };
  return (
    <SimpleForm {...props} onFailure={onFailure}>
      <div style={{ width: '100%' }}>
        <Grid container spacing={3}>
          <Row
            cmp={TextInput}
            label={`${translate('resources.tags.fields.title')} *`}
            source="title"
            fullWidth
            validation={[required()]}
          />
          <Row
            cmp={CategorySelect}
            label={`${translate('resources.tags.fields.category')} *`}
            source="categoryId"
            reference="categories"
            fullWidth
          />
        </Grid>
      </div>
    </SimpleForm>
  );
};
