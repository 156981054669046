import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import mapboxgl from 'mapbox-gl';

export const parseGeo = (geoData) => {
  let locality, region, country, postcode, returnStr;
  if (geoData.context) {
    geoData.context.forEach((v, i) => {
      if (v.id.indexOf('locality') >= 0) {
        locality = v.text;
      }
      if (v.id.indexOf('postcode') >= 0) {
        postcode = v.text;
      }
      if (v.id.indexOf('region') >= 0) {
        region = v.text;
      }
      if (v.id.indexOf('country') >= 0) {
        country = v.text;
      }
    });
  }
  if (postcode && region && country) {
    returnStr = `${geoData.address} ${geoData.text}, ${locality} ${region} ${postcode}, ${country}`;
  } else {
    returnStr = geoData.place_name;
  }
  return {
    lat: geoData.center[1],
    lng: geoData.center[0],
    number: geoData.address,
    address: geoData.text,
    locality,
    region,
    postcode,
    country,
    concat: returnStr,
    complete: geoData.place_name,
  };
};

export const initializeGeocoder = ({
  geocoderContainer,
  address,
  onResult,
}) => {
  const geocoder = new MapboxGeocoder({
    keepOpen: false,
    accessToken: mapboxgl.accessToken,
    mapboxgl: mapboxgl,
    queryParams: {
      country: 'it',
      types: 'address',
    },
  });

  geocoder.addTo(geocoderContainer.current);
  geocoder.setPlaceholder(address);
  geocoder.on('result', (e) => {
    onResult(e);
  });
  return geocoder;
};
