import {
  SimpleForm,
  TextInput,
  DateInput,
  SelectInput,
  useTranslate,
} from 'react-admin';
import { required, regex, email, choices } from 'react-admin';
import Grid from '@material-ui/core/Grid';
import dayjs from 'dayjs';
import MapboxGeocoder from '../components/MapBoxGeocoder';
import axios from 'axios';

export const roleOptions = [
  { id: 'superadmin', name: 'superadmin' },
  { id: 'admin', name: 'admin' },
  { id: 'user', name: 'user' },
];

export const transformer = (data) =>
  Object.assign({}, data, {
    date_of_birth:
      data.date_of_birth && data.date_of_birth.length > 0
        ? data.date_of_birth
        : null,
    delete: data.delete && data.delete.length > 0 ? data.delete : null,
  });

export const UserTitle = ({ record }) => {
  return (
    <span>
      User {record ? `"${record.first_name} ${record.last_name}"` : ''}
    </span>
  );
};

const Row = ({ cmp: Cmp, ...props }) => (
  <Grid item xs={12} lg={6}>
    <Cmp {...props} />
  </Grid>
);

export const Form = (props) => {
  const { disableemail } = props;
  const translate = useTranslate();

  const uniqueMail = (value, allValues) => {
    return axios
      .put(
        `${process.env.REACT_APP_BACKEND_ENDPOINT_PUBLIC}/auth/email-exists`,
        { email: value },
        {
          headers: {
            Authorization: `Bearer ${
              JSON.parse(localStorage.getItem('auth')).token
            }`,
          },
        }
      )
      .then(({ data }) => {
        if (data.mail_exists) {
          return translate('resources.users.errors.email');
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const validation = {
    email: [required(), email(), uniqueMail],
    firstName: [required()],
    lastName: [required()],
    role: [
      required(),
      choices(
        ['superadmin', 'admin', 'user'],
        translate('resources.users.errors.role')
      ),
    ],
    phoneNumber: [
      regex(
        /^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s\./0-9]*$/,
        translate('resources.users.errors.phone_number')
      ),
    ],
    dateOfBirth: (value) => {
      if (dayjs(value).isBefore('1900-01-01')) {
        return translate('resources.users.errors.date_of_birth');
      }
    },
  };

  return (
    <SimpleForm {...props}>
      <div style={{ width: '100%' }}>
        <Grid container spacing={3}>
          <Row
            cmp={TextInput}
            label={translate('resources.users.fields.first_name')}
            source="first_name"
            fullWidth
            validate={validation.firstName}
          />
          <Row
            cmp={TextInput}
            disabled={disableemail}
            label={translate('resources.users.fields.email')}
            source="email"
            fullWidth
            validate={validation.email}
          />
          <Row
            cmp={TextInput}
            label={translate('resources.users.fields.last_name')}
            source="last_name"
            fullWidth
            validate={validation.lastName}
          />
          <Row
            cmp={SelectInput}
            label={translate('resources.users.fields.role')}
            source="role"
            choices={roleOptions}
            fullWidth
            validate={validation.role}
          />
          <Row
            cmp={TextInput}
            label={translate('resources.users.fields.phone_number')}
            source="phone_number"
            fullWidth
            validate={validation.phoneNumber}
          />
          <Row
            cmp={DateInput}
            label={translate('resources.users.fields.date_of_birth')}
            source="date_of_birth"
            fullWidth
            validate={validation.dateOfBirth}
          />
          <Row
            cmp={MapboxGeocoder}
            source="billing_address"
            label={translate('resources.users.fields.billing_address')}
            fullWidth
            fullRow
          />
        </Grid>
      </div>
    </SimpleForm>
  );
};
