import * as React from 'react';
import { Create } from 'react-admin';
import { Form } from './form';

export const TagsCreate = (props) => {
  return (
    <Create {...props}>
      <Form />
    </Create>
  );
};
