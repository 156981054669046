import { SimpleForm, TextInput, useTranslate } from 'react-admin';
import Row from './../components/Row';
import FileUploader from '../components/FileUploader';
import Grid from '@material-ui/core/Grid';
import { BooleanInput } from 'react-admin';

export const CategoryTitle = ({ record }) => {
  return <span>Category {record ? `"${record.title}"` : ''}</span>;
};

export const Form = (props) => {
  const translate = useTranslate();

  return (
    <SimpleForm {...props}>
      <Grid container spacing={3}>
        <Row cmp={TextInput} source="title" />
        <Row
          label={translate('resources.categories.fields.featured')}
          cmp={BooleanInput}
          source="featured"
        />
      </Grid>
      <Grid container spacing={3}>
        <Row
          label={translate('resources.categories.fields.featured_image')}
          cmp={FileUploader}
          maxNumberOfFiles={1}
          triggerText={translate('resources.categories.trigger.featured_image')}
          source="featured_image"
        />
        <Row
          label={translate('resources.categories.fields.card_image')}
          cmp={FileUploader}
          maxNumberOfFiles={1}
          triggerText={translate('resources.categories.trigger.card_image')}
          source="card_image"
        />
      </Grid>
    </SimpleForm>
  );
};
