import { RadioButtonGroupInput, useTranslate } from 'react-admin';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Row from './../../components/Row';

export const Step0 = (props) => {
  const translate = useTranslate();
  return (
    <>
      <div style={{ width: '100%' }}>
        <Grid container spacing={3}>
          <Row
            cmp={RadioButtonGroupInput}
            source="step0.is_remote"
            label={translate(
              'resources.experience-drafts.form.step0.is_remote.label'
            )}
            choices={[
              {
                id: true,
                name: translate(
                  'resources.experience-drafts.form.step0.is_remote.remote'
                ),
              },
              {
                id: false,
                name: translate(
                  'resources.experience-drafts.form.step0.is_remote.live'
                ),
              },
            ]}
          />
          <Row
            cmp={RadioButtonGroupInput}
            source="step0.typology"
            label={translate(
              'resources.experience-drafts.form.step0.typology.label'
            )}
            choices={[
              {
                id: 'experience',
                name: translate(
                  'resources.experience-drafts.form.step0.typology.experience'
                ),
              },
              {
                id: 'job',
                name: translate(
                  'resources.experience-drafts.form.step0.typology.job'
                ),
              },
            ]}
          />
        </Grid>
      </div>
    </>
  );
};
