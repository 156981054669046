import { Button } from '@material-ui/core';
import IconCheckCircle from '@material-ui/icons/CheckCircle';
import { useRefresh, useTranslate, useNotify } from 'react-admin';

import axios from 'axios';

export default (props) => {
  const translate = useTranslate();
  const refresh = useRefresh();
  const notify = useNotify();
  const approveDraft = () =>
    axios
      .put(
        `${process.env.REACT_APP_BACKEND_ENDPOINT}/experience-drafts/approve/${props.record.id}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${
              JSON.parse(localStorage.getItem('auth')).token
            }`,
          },
        }
      )
      .then(() => {
        notify(translate('resources.action.success.generic'), {
          type: 'success',
        });
        refresh();
      })
      .catch((e) => {
        notify(e.response.data.error.message, { type: 'warning' });
      });
  return (
    <Button
      color="primary"
      disabled={props.record.status !== 'submitted'}
      onClick={approveDraft}
    >
      <IconCheckCircle />
      {translate('resources.experience-drafts.actions.approve')}
    </Button>
  );
};
