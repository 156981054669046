import { NumberInput, useTranslate, TextInput } from 'react-admin';
import Grid from '@material-ui/core/Grid';
import Row from './../../components/Row';

export const Step4 = () => {
  const translate = useTranslate();

  const formatTime = (time) => {
    const hours = Math.floor(time / 60);
    const minutes = time % 60;
    return `${hours}:${minutes}`;
  };

  return (
    <>
      <div style={{ width: '100%' }}>
        <Grid container spacing={3}>
          <Row
            cmp={NumberInput}
            source="step4.price"
            label={translate('resources.experience-drafts.form.step4.price')}
            fullWidth
          />
          <Row
            cmp={TextInput}
            label={translate(
              'resources.experience-drafts.form.step4.duration.label'
            )}
            source="step4.duration"
            format={(v) => formatTime(v)}
            fullWidth
          />
        </Grid>
      </div>
    </>
  );
};
