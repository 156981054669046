import { SRLWrapper } from 'simple-react-lightbox';
import Carousel from 'react-material-ui-carousel';
import { useRecordContext } from 'react-admin';
import * as _ from 'lodash';

// eslint-disable-next-line import/no-anonymous-default-export
export default (props) => {
  const { source } = props;
  const record = useRecordContext(props);
  const f = _.get(record, source);
  const field = !Array.isArray(f) ? [f] : f;
  if (!f || field.length === 0) {
    return null;
  }
  return (
    <SRLWrapper>
      <Carousel>
        {field.map((image, key) => (
          <a href={image} key={key}>
            <img src={image} key={key} alt="" style={{ maxWidth: '100%' }} />
          </a>
        ))}
      </Carousel>
    </SRLWrapper>
  );
};
