import React, { useState, useRef, useEffect } from 'react';
import mapboxgl from 'mapbox-gl';
import { useInput } from 'react-admin';
import { initializeGeocoder, parseGeo } from '../MapBox/util';
import './style.scss';

const MapGeocoder = ({ ...props }) => {
  const {
    input: { onChange, ...rest },
  } = useInput(props);

  const [address, setAddress] = useState(rest.value);
  const [geocoder, setGeocoder] = useState(null);
  const geocoderContainer = useRef(null);

  useEffect(() => {
    mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;

    if (!geocoder) {
      setGeocoder(
        initializeGeocoder({
          geocoderContainer,
          address,
          onResult: (e) => {
            if (e.result) {
              const location = parseGeo(e.result);
              setAddress(location.complete);
              rest.value = location.complete;
              onChange(location.complete);
              rest.onBlur();
            }
          },
        })
      );
    }
  }, []);

  useEffect(() => {
    if (geocoder) {
      geocoder.setPlaceholder(address);
    }
  }, [geocoder, address]);

  return (
    <div className="geocoder-map MapAutoCompleteWrapper">
      <div
        className="geocoder GeocoderContainer"
        ref={(el) => (geocoderContainer.current = el)}
      ></div>
    </div>
  );
};

MapGeocoder.defaultProps = {
  source: '',
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (props) => {
  return <MapGeocoder {...props} />;
};
