import {
  List,
  Datagrid,
  FunctionField,
  TextField,
  DateField,
  useTranslate,
  SelectInput,
  DateInput,
  ReferenceInput,
  AutocompleteInput,
} from 'react-admin';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import BusinessCancel from '../components/BookingsButtons/BusinessCancel';
import UserCancel from '../components/BookingsButtons/UserCancel';
import FullRefund from '../components/BookingsButtons/FullRefund';
import PartialRefund from '../components/BookingsButtons/PartialRefund';

dayjs.extend(utc);
dayjs.extend(timezone); // dependent on utc plugin

export const BookingList = (props) => {
  const translate = useTranslate();
  const filterDefaultValues = {
    dateStart: dayjs().tz('Europe/Rome').startOf('month').format('YYYY-MM-DD'),
    dateEnd: dayjs().tz('Europe/Rome').endOf('month').format('YYYY-MM-DD'),
  };

  const postFilters = [
    <DateInput alwaysOn source="dateStart" />,
    <DateInput alwaysOn source="dateEnd" />,
    <SelectInput
      choices={[
        { id: 'complete', name: 'Completati' },
        { id: 'pending', name: 'La tua prossima esperienza' },
        { id: 'pending-payment', name: 'In Attesa di Pagamento' },
      ]}
      alwaysOn
      source="status"
    />,
    <ReferenceInput
      label={translate('resources.bookings.filter.experience')}
      source="id"
      reference="experiences"
      alwaysOn
    >
      <AutocompleteInput optionText="title" />
    </ReferenceInput>,
    <ReferenceInput
      label={translate('resources.bookings.filter.client')}
      source="userId"
      reference="users"
      alwaysOn
    >
      <AutocompleteInput optionText="email" />
    </ReferenceInput>,
  ];

  return (
    <List
      {...props}
      filters={postFilters}
      filterDefaultValues={filterDefaultValues}
      sort={{ field: 'id', order: 'DESC' }}
      bulkActionButtons={false}
    >
      <Datagrid>
        <TextField source="id" />
        <FunctionField
          sortable={false}
          label={translate('resources.bookings.fields.user')}
          render={(booking) =>
            `${booking.user.first_name} ${booking.user.last_name} (${booking.user.email})`
          }
        />
        <TextField
          source="n_participants"
          label={translate('resources.bookings.fields.n_participants')}
          sortable={false}
        />
        <TextField
          source="transaction.amount"
          label={translate('resources.bookings.fields.amount')}
          sortable={false}
        />
        <TextField
          source="experience.title"
          label={translate('resources.bookings.fields.experience')}
          sortable={false}
        />
        <DateField
          source="start"
          label={translate('resources.bookings.fields.start')}
          showTime
          options={{
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
          }}
          sortable={false}
        />
        <DateField
          source="end"
          label={translate('resources.bookings.fields.end')}
          showTime
          options={{
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
          }}
          sortable={false}
        />
        <FunctionField
          sortable={false}
          label={translate('resources.bookings.fields.status')}
          render={(booking) =>
            translate(`resources.bookings.status.${booking.status}`)
          }
        />
        <BusinessCancel />
        <UserCancel />
        <FullRefund />
        <PartialRefund />
      </Datagrid>
    </List>
  );
};
