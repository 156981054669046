import * as React from 'react';
import {
  List,
  Datagrid,
  DateField,
  TextField,
  EditButton,
  ShowButton,
  FunctionField,
} from 'react-admin';
import { useTranslate } from 'react-admin';
import { Icon } from '@material-ui/icons/ContactMail';

export const ExperiencesList = (props) => {
  const translate = useTranslate();
  return (
    <List
      {...props}
      bulkActionButtons={false}
      sort={{ field: 'id', order: 'DESC' }}
    >
      <Datagrid>
        <TextField source="id" />
        <TextField source="title" />
        <TextField source="status" />
        <FunctionField
          label={translate('resources.experiences.fields.user')}
          render={(exp) =>
            `${exp.userBusiness.user.first_name} ${exp.userBusiness.user.last_name}`
          }
        />
        <DateField source="approved_at" showTime />
        <ShowButton basePath="/experiences" />
      </Datagrid>
    </List>
  );
};
