import {
  ReferenceArrayInput,
  SelectArrayInput,
  useTranslate,
} from 'react-admin';
import Grid from '@material-ui/core/Grid';
import RichTextInput from 'ra-input-rich-text';
import SimpleReactLightbox from 'simple-react-lightbox';
import Row from './../../components/Row';
import ImageFullScreenField from '../../components/ImageFullScreenField';
import GalleryFullScreenField from '../../components/GalleryFullScreenField';
import VideoField from '../../components/VideoField';
import './step2.scss';

const Tags = () => {
  const translate = useTranslate();
  return (
    <ReferenceArrayInput
      label={translate('resources.experience-drafts.form.step2.tags')}
      reference="tags"
      source="step2.tags"
      target="step2.tags"
    >
      <SelectArrayInput optionText="title" fullWidth />
    </ReferenceArrayInput>
  );
};
export const Step2 = (props) => {
  const translate = useTranslate();
  return (
    <SimpleReactLightbox>
      <div style={{ width: '100%' }}>
        <Grid container spacing={3}>
          <Row
            cmp={RichTextInput}
            source="step2.description"
            label={translate(
              'resources.experience-drafts.form.step2.description'
            )}
            fullWidth
            fullRow
          />
          <Row cmp={Tags} fullWidth fullRow />
          <Row
            cmp={VideoField}
            source="step2.featured_video"
            label={translate(
              'resources.experience-drafts.form.step2.featured_video'
            )}
          />
          <Row
            cmp={ImageFullScreenField}
            source="step2.featured_image"
            label={translate(
              'resources.experience-drafts.form.step2.featured_image'
            )}
          />
          <Row
            cmp={GalleryFullScreenField}
            source="step2.gallery"
            label={translate('resources.experience-drafts.form.step2.gallery')}
            fullWidth
            fullRow
          />
        </Grid>
      </div>
    </SimpleReactLightbox>
  );
};
