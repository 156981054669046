import { createTheme } from '@material-ui/core/styles';
import green from '@material-ui/core/colors/green';
import red from '@material-ui/core/colors/red';
import amber from '@material-ui/core/colors/amber';
import blue from '@material-ui/core/colors/blue';
import grey from '@material-ui/core/colors/grey';

const primary = { main: '#EE3A53' };
const secondary = { main: '#D85C45' };

const theme = createTheme({
  palette: {
    primary,
    secondary,
    error: { main: red[700] },
    warning: { main: amber[700] },
    info: { main: blue[400] },
    success: { main: green[500] },
    contrastThreshold: 3,
    tonalOffset: 0.2,
  },
  typography: {
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Arial',
      'sans-serif',
    ].join(','),
  },
  overrides: {
    MuiButton: {
      root: {
        color: grey[50],
      },
    },
  },
});

export default theme;
