import { Button } from '@material-ui/core';
import IconCheckCircle from '@material-ui/icons/CheckCircle';
import { useRefresh, useTranslate, useNotify } from 'react-admin';
import dayjs from 'dayjs';

import axios from 'axios';

export default (props) => {
  const translate = useTranslate();
  const refresh = useRefresh();
  const notify = useNotify();
  const approveVerifyUser = () =>
    axios
      .patch(
        `${process.env.REACT_APP_BACKEND_ENDPOINT}/user-business-requests/${props.record.userBusiness.id}/approve`,
        {
          profile_verified: dayjs().format('YYYY-MM-DD HH:mm:ss'),
        },
        {
          headers: {
            Authorization: `Bearer ${
              JSON.parse(localStorage.getItem('auth')).token
            }`,
          },
        }
      )
      .then(() => {
        notify(
          translate(
            'resources.user-business-requests.response.approve-verify-success'
          ),
          { type: 'success' }
        );
      })
      .catch((e) => {
        notify(e.response.data.error.message, { type: 'warning' });
      });
  return (
    <Button color="primary" onClick={approveVerifyUser}>
      <IconCheckCircle />
      {translate('resources.user-business-requests.actions.approve-verify')}
    </Button>
  );
};
