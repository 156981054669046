import * as React from 'react';
import { Edit } from 'react-admin';
import { ExperienceTitle, Form, transformer } from './form';

export const ExperiencesEdit = (props) => {
  return (
    <Edit title={<ExperienceTitle />} {...props} transform={transformer}>
      <Form />
    </Edit>
  );
};
