import {
  SimpleForm,
  TextInput,
  BooleanInput,
  ReferenceInput,
  useTranslate,
  required,
  regex,
  maxLength,
  FunctionField,
  AutocompleteInput,
} from 'react-admin';
import Grid from '@material-ui/core/Grid';
import SimpleReactLightbox from 'simple-react-lightbox';
import ImageFullScreen from '../components/ImageFullScreen';

export const UserTitle = ({ record }) => {
  return (
    <span>
      User {record ? `"${record.first_name} ${record.last_name}"` : ''}
    </span>
  );
};

const Row = ({ cmp: Cmp, ...props }) => (
  <Grid item xs={12} lg={6}>
    <Cmp {...props} />
  </Grid>
);

export const UserSelect = (props) => {
  return (
    <ReferenceInput {...props} filter={{ role: 'user' }}>
      <AutocompleteInput optionText="email" optionValue="id" />
    </ReferenceInput>
  );
};

export const Form = (props) => {
  const { disableFields } = props;
  const translate = useTranslate();

  const validation = {
    biography: [required(), maxLength(1000)],
    tax_id: [
      required(),
      regex(
        /^(^(ATU[0-9]{8}|BE0[0-9]{9}|BG[0-9]{9,10}|CY[0-9]{8}L|CZ[0-9]{8,10}|DE[0-9]{9}|DK[0-9]{8}|EE[0-9]{9}|(EL|GR)[0-9]{9}|ES[0-9A-Z][0-9]{7}[0-9A-Z]|FI[0-9]{8}|FR[0-9A-Z]{2}[0-9]{9}|GB([0-9]{9}([0-9]{3})?|[A-Z]{2}[0-9]{3})|HU[0-9]{8}|IE[0-9]S[0-9]{5}L|IT[0-9]{11}|LT([0-9]{9}|[0-9]{12})|LU[0-9]{8}|LV[0-9]{11}|MT[0-9]{8}|NL[0-9]{9}B[0-9]{2}|PL[0-9]{10}|PT[0-9]{9}|RO[0-9]{2,10}|SE[0-9]{12}|SI[0-9]{8}|SK[0-9]{10}))|(^([A-Z]{6}[0-9LMNPQRSTUV]{2}[ABCDEHLMPRST]{1}[0-9LMNPQRSTUV]{2}[A-Z]{1}[0-9LMNPQRSTUV]{3}[A-Z]{1})$|([0-9]{11})$)/,
        translate('resources.user-business-requests.errors.tax_id')
      ),
    ],
    iban: [
      required(),
      regex(
        /^([A-Z]{2}[ \\-]?[0-9]{2})(?=(?:[ \\-]?[A-Z0-9]){9,30}$)((?:[ \\-]?[A-Z0-9]{3,5}){2,7})([ \\-]?[A-Z0-9]{1,3})?$/,
        translate('resources.user-business-requests.errors.iban')
      ),
    ],
    job_title: [required(), maxLength(50)],
    userId: [required()],
  };

  return (
    <SimpleForm {...props}>
      <div style={{ width: '100%' }}>
        <SimpleReactLightbox>
          <Grid container spacing={3}>
            <Row
              cmp={TextInput}
              label={translate(
                'resources.user-business-requests.fields.biography'
              )}
              source="biography"
              fullWidth
              fullRow
              multiline
              validate={validation.biography}
            />
          </Grid>
          <Grid container spacing={3}>
            <Row
              cmp={TextInput}
              label={translate(
                'resources.user-business-requests.fields.tax_id'
              )}
              source="tax_id"
              fullWidth
              validate={validation.tax_id}
            />
            <Row
              cmp={TextInput}
              label={translate('resources.user-business-requests.fields.iban')}
              source="iban"
              fullWidth
              validate={validation.iban}
            />
            <Row
              cmp={TextInput}
              label={translate(
                'resources.user-business-requests.fields.job_title'
              )}
              source="job_title"
              fullWidth
              validate={validation.job_title}
            />
            {!disableFields && (
              <Row
                cmp={UserSelect}
                label="User"
                source="userId"
                reference="users"
                target="userId"
                validate={validation.userId}
              />
            )}
            <Row
              label={translate(
                'resources.user-business-requests.fields.profile_verified'
              )}
              cmp={BooleanInput}
              source="profile_verified"
            />
            <Row
              cmp={FunctionField}
              render={(record) => {
                return (
                  <div style={{ display: 'flex' }}>
                    <ImageFullScreen
                      key={'recordBusinessDocs1'}
                      source={
                        record.documents ? JSON.parse(record.documents)[0] : ''
                      }
                    />
                    <ImageFullScreen
                      key={'recordBusinessDocs2'}
                      source={
                        record.documents ? JSON.parse(record.documents)[1] : ''
                      }
                    />
                  </div>
                );
              }}
              source="documents"
              label={translate(
                'resources.user-business-requests.fields.documents'
              )}
              fullWidth
              fullRow
            />
          </Grid>
        </SimpleReactLightbox>
      </div>
    </SimpleForm>
  );
};
